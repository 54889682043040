import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import CustomTabs from "../../components/CustomTabs";

import * as baseStyles from "../index.module.css";
import * as styles from "./personalized.module.css";

import creditCards from "../../images/credit-cards.png";
import interacLogo from "../../images/InteracLogo.svg";

const data = [
  {
    title: "Interchange Plus",
    description:
      "Interchange Plus Pricing, also known as Cost + Pricing, is extremely common among larger processors because the processor’s profit margin is guaranteed. The merchant might pay slightly higher rates on some card types in exchange for the benefit of a fixed markup from the processor, which with Central is 100% non-variable and protected from margin increases and competing Cost + offers. This model is best suited to merchants who process mostly keyed and non-qualified transactions.",
    rates: {
      cards: {
        0: { main: "1.33%", foot: "Interchange Plus 0.35%" },
        25000: { main: "1.38%", foot: "Interchange Plus 0.30%" },
        50000: { main: "1.28%", foot: "Interchange Plus 0.25%" },
        100000: { main: "1.23%", foot: "Interchange Plus 0.20%" },
        250000: { main: "1.18%", foot: "Interchange Plus 0.15%" },
        1000000: { main: "1.16%", foot: "Interchange Plus 0.10%" },
        5000000: { main: "1.13%", foot: "Interchange Plus 0.08%" },
      },
      interac: {
        0: { main: "5¢" },
        25000: { main: "5¢" },
        50000: { main: "5¢" },
        100000: { main: "5¢" },
        250000: { main: "4¢" },
        1000000: { main: "4¢" },
        5000000: { main: "4¢" },
      },
    },
  },
  {
    title: "Differential",
    description:
      "Differential Pricing can be seen as a hybrid between Cost + pricing and Flat Rate pricing. The value of a Differential largely depends on the mix of cards accepted by the merchant. In most cases, processors who offer Differential Pricing will provide a fixed Discount Rate for qualified cards and a fixed non-qualified markup on the Cost of non-qualified cards. This type of pricing has the potential to generate incredible savings for merchants who accept mostly basic cards through swiped or tapped transactions.",
    rates: {
      cards: {
        0: { main: "1.59%", foot: "Non Qualified 1.62%" },
        25000: { main: "1.55%", foot: "Non Qualified 1.62%" },
        50000: { main: "1.50%", foot: "Non Qualified 1.62%" },
        100000: { main: "1.44%", foot: "Non Qualified 1.62%" },
        250000: { main: "1.44%", foot: "Non Qualified 1.62%" },
        1000000: { main: "1.42%", foot: "Non Qualified 1.62%" },
        5000000: { main: "", foot: "Non Qualified 1.62%" },
      },
      interac: {
        0: { main: "5¢" },
        25000: { main: "4¢" },
        50000: { main: "4¢" },
        100000: { main: "4¢" },
        250000: { main: "4¢" },
        1000000: { main: "4¢" },
        5000000: { main: "4¢" },
      },
    },
  },

  {
    title: "Flat Rate",
    description:
      "Flat Rate pricing typically has one or t0wo fixed percentages and per transaction fees for the entire range of cards processed by the merchant, regardless of the type of card or how it is charged. Many merchants feel that this pricing model is the easiest to understand because it is very simple. This simplicity can come at a massive cost to merchants as Interchange Rates vary to an alarming degree, which forces the processor to charge a particularly high rate to accommodate their Cost.",
    rates: {
      cards: {
        0: { main: "3%" },
        25000: { main: "3%" },
        50000: { main: "3%" },
        100000: { main: "3%" },
        250000: { main: "3%" },
        1000000: { main: "3%" },
        5000000: { main: "3%" },
      },
      interac: {
        0: { main: "5¢" },
        25000: { main: "5¢" },
        50000: { main: "5¢" },
        100000: { main: "5¢" },
        250000: { main: "5¢" },
        1000000: { main: "5¢" },
        5000000: { main: "5¢" },
      },
    },
  },
  {
    title: "Tiered",
    description:
      "Tiered Pricing often consists of two or more “tiers” for different kinds of cards such as Consumer, Business and Premium credit cards. This pricing model is increasingly popular because it allows a merchant with previous processing history to navigate their pricing to find the best cost for their most common card types. On the other hand, this can be hazardous for new businesses who are unsure what mix of card types to expect.",
    rates: {
      cards: {
        0: { main: "1.72%", foot: "Mid-Qualified 2.25% \n Non-Qualified 2.65%" },
        25000: { main: "1.70%", foot: "Mid-Qualified 2.25% \n Non-Qualified 2.65%" },
        50000: { main: "1.68%", foot: "Mid-Qualified 2.25% \n Non-Qualified 2.65%" },
        100000: { main: "1.65%", foot: "Mid-Qualified 2.25% \n Non-Qualified 2.65%" },
        250000: { main: "1.60%", foot: "Mid-Qualified 2.25% \n Non-Qualified 2.65%" },
        1000000: { main: "1.55%", foot: "Mid-Qualified 2.25% \n Non-Qualified 2.65%" },
        5000000: { main: "1.50%", foot: "Mid-Qualified 2.25% \n Non-Qualified 2.65%" },
      },
      interac: {
        0: { main: "5¢" },
        25000: { main: "5¢" },
        50000: { main: "5¢" },
        100000: { main: "5¢" },
        250000: { main: "5¢" },
        1000000: { main: "4¢" },
        5000000: { main: "4¢" },
      },
    },
  },
];

export default function Personalized() {
  const [selectedType, setSelectedType] = useState(0);
  const [selectedVolume, setSelectedVolume] = useState("0");

  const handleSelectChange = (e) => {
    console.log(e.target.value);
    setSelectedVolume(e.target.value);
  };

  return (
    <Layout>
      <Helmet>
        <title>Personalized Pricing | MyEZSolutions</title>
      </Helmet>

      <div className={baseStyles.pageContent}>
        <div className={baseStyles.pageTitle}>
          <h1>Personalized Pricing</h1>
        </div>

        <div className={styles.root}>
          <h2>Pricing Types</h2>
          <CustomTabs tabs={data} onTabChange={setSelectedType} />
          <h2>Calculator</h2>
          <label for="type">Estimated monthly card volume:</label>
          <select
            className={styles.dropdown}
            onChange={(e) => handleSelectChange(e)}
            name="volume"
          >
            <option value="0" selected>
              $0 - $25,000
            </option>
            <option value="25000">$25,001 - $50,000</option>
            <option value="50000">$50,001 - $100,000</option>
            <option value="100000">$100,001 - $250,000</option>
            <option value="250000">$250,001 - $1,000,000</option>
            <option value="1000000">$1,000,001 - $5,000,000</option>
            <option value="5000000">$5,000,000 +</option>
          </select>
          <div class={styles.pricesContainer}>
            <div className={styles.priceGridItem}>
              <img src={creditCards} />
            </div>
            <div className={styles.priceGridItem}>
              <h3>Lowest Credit Card</h3>
              <h1>{data[selectedType].rates.cards[selectedVolume].main}</h1>
              <h4>{data[selectedType].rates.cards[selectedVolume].foot}</h4>
            </div>
            <div className={styles.priceGridItem}>
              <img src={interacLogo} />
            </div>
            <div className={styles.priceGridItem}>
              <h3>Interac Chip & PIN</h3>
              <h1>{data[selectedType].rates.interac[selectedVolume].main}</h1>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
